import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Header from '../components/header';

export default function Experience() {
  const [activeId, setActiveId] = useState('001');

  const handleClick = (id) => {
    setActiveId(id);
  };
  let jobs = [
  {
    company:"Frugal Innovation Hub",
    role:"Software Developer",
    date:"October 2023 - Present",
    description:"I work here as a Software Engineer. Although being here for a short period of time, I have been able to contribute a lot due to my vast experience in the past. I worked on Equation 2 Success , which is a project for students to report bullying or any such cases in school. I was responsible for refactoring the whole codebase to a repository development pattern , which is really useful for a plug-n-play kind of development. Among other things I was also responsible for deploying the backend on AWS EC2 server for seamless user experience. For easy and seamless development I also employed CI/CD pipelines.",
    id:"001"
  },
  {
    company:"Tyrannix Pvt Ltd",
    date:"July 2021 – August 2023",
    role:"Software Engineer",
    description:"I worked as a Software Engineer on large-scale projects leveraging my knowledge in machine learning and web development. One significant project involved preprocessing 8,000 samples into over 35,000 grayscale images, utilizing YOLOv5 for image recognition, and achieving an average accuracy of 95%. I also created scripts to automate testing and improve model accuracy. Another key project was developing the UI of a gaming application used by over 500,000 daily users, utilizing React Redux for state management. At HPD, I implemented a microservices architecture using NodeJs, AWS, and Redis for real-time data processing and scalable solutions. I led the RealX project, integrating national ID verification, secure payment gateways, and blockchain APIs, enhancing successful payments by 20%. Additionally, I developed Pupperazy using NodeJs and ReactJs, which increased sales by 45% and optimized appointment bookings with machine learning, improving revenue by 20%.",
    id:"002"
  },
  {
    company:"Skinzy Solutions Pvt Ltd",
    date:"February 2020 – December 2020",
    role:"Machine Learning Intern",
    description:"As a Machine Learning Intern on a team of 10, I focused on researching and implementing object classification algorithms. I reviewed research papers and blogs to identify the best models, then developed a pipeline for data preprocessing and model training. Over 11 months, I engineered and deployed a cutting-edge machine learning model on AWS, using SageMaker and EC2, achieving a 20% boost in predictive accuracy through hyperparameter tuning and feature engineering. I collaborated across teams, utilizing AWS services like S3, Lambda, and Step Functions to create efficient end-to-end machine learning pipelines.",
    id:"003"
  },
  {
    company:"Sahu Technologies",
    date:"January 2019 - February 2019",
    role:"Web Developer Intern",
    description:"Here as a web developer intern, my responsibilities mainly included making interactive and responsive static web pages with HTML5 and CSS3. I was responsible for creating pages from a design provided and also checking their compatibility with multiple devices.",
    id:"004"
  },
  ]

  return (
    <div>
      <Header />
      <VerticalTimeline lineColor={'#ddd'}>
        {
          jobs?.map((e)=>{
            return(
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                key={e.id}
                id={e.id}
                iconStyle={{ display: 'none' }}
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                // className={`timeline-item ${activeId === e.id ? 'active' : 'active'}`}
                date={e.date}
                onClick={() => handleClick(e.id)}
              >
                <h3 className="vertical-timeline-element-title">{e.company}</h3>
                <h4 className="vertical-timeline-element-subtitle">{e.role}</h4>
                <p>
                  {e.description}
                </p>
              </VerticalTimelineElement>
            )
          })
        }
      </VerticalTimeline>
    </div>
  );
}



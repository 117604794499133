import React, { useEffect, useState } from "react";
import Header from "../components/header";
import image from "../assets/images/output-onlinegiftools.gif";
// import image from "../assets/coding.svg";
import ContactMeModal from "../components/contactme-modal";
import { resize } from "../components/cursor-2";

export default function Home() {
  useEffect(() => {
    resize();
  });
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Header />
      <ContactMeModal showModal={showModal} setShowModal={setShowModal} />
      <div className="main-div">
        <div className="text-div">
          <div className="hi-div">Heyy I'm,</div>
          <div className="name-div">Harshhh...</div>
          <div className="content-div">
            <span className="content">
              My name is Harsh. I am a Masters Student at Santa Clara University
              pursuing Computer Science & Engineering. I wish to join a startup
              which has amazing founders and a good lead. I am one who wishes to
              grow along with the company. I am profound in FullStack
              Development, with FrontEnd in React.js and backend in Node.js or
              Node.ts. Along with that I come with a startup mindset where I know
              how accelerated things need to be and how fast you need to
              deliver. So I would like to add that a lot of times there is a
              question, how many hours can you commit a week, people say 40, I
              say 168 hours, that too because god has put a limit on those
              numbers, else I would put even more. In me you will find an
              employee who may not come with all knowledge but comes with a very
              strong willingness to learn.
            </span>
          </div>
        </div>
        <div className="humaans-image">
          <img src={image} alt="" />
        </div>
      </div>
      <div
        className="contactme-button animate__animated animate__bounce "
        onClick={() => {
          setShowModal(true);
        }}
      >
        <span className="contactBox-span"></span>
        <span className="contactBox-span"></span>
        <span className="contactBox-span"></span>
        <span className="contactBox-span"></span>
        <h1>CONTACT ME</h1>
      </div>
    </div>
  );
}

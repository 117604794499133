import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import resume from "../assets/images/Harsh Resume.pdf";
import Cursor2 from "./cursor-2";
import { FaBars, FaTimes } from "react-icons/fa"; // Install react-icons if not already
import linkedInIcon from "../assets/images/linkedIn.png";
import githubIcon from "../assets/images/github.png";
import instagramIcon from "../assets/images/instagram.png";
import twitterIcon from "../assets/images/twitter.png";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Cursor2();
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebarWithDelay = (path) => {
    setTimeout(() => {
      setIsOpen(false);
      navigate(path);
    }, 200); // Adjust the delay time (in milliseconds) as needed
  };

  return (
    <header className="header-main">
      <div className="home-button" onClick={() => closeSidebarWithDelay("/")}>
        <div className="home-button-div">HARSH ZOTA</div>
      </div>
      <nav className={`nav-links ${isOpen ? "open" : ""}`}>
        {
          isOpen &&
        <div className="link" onClick={() => closeSidebarWithDelay("/")}>
          Home
        </div>
        }
        {/* <div className="link" onClick={() => closeSidebarWithDelay("/projects")}>
          Projects
        </div> */}
        <div className="link" onClick={() => closeSidebarWithDelay("/experience")}>
          Experience
        </div>
        <a className="link" download="harsh-resume" href={resume}>
          Resume
        </a>
        <div className="social-media">
          <a href="https://www.linkedin.com/in/harsh-zota-0a639716a/" target="_blank" rel="noopener noreferrer">
            <img src={linkedInIcon} alt="LinkedIn" />
          </a>
          <a href="https://github.com/Haaarrrssshhh/" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="GitHub" />
          </a>
          <a href="https://www.instagram.com/harshzota99/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://twitter.com/HarshZota" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </div>
      </nav>
      <div className="menu-icon" onClick={toggleSidebar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </header>
  );
}

import React from "react";
import Modal from "react-modal";
import image from "../assets/images/linkedIn.png";
import image2 from "../assets/images/github.png";
import image3 from "../assets/images/instagram.png";
import image4 from "../assets/images/twitter.png";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};

export default function ContactMeModal({ showModal, setShowModal }) {
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={customStyles}
        className="main-modal"
      >
        <div className="info-div">
          <input
            type="text"
            className="name-number-email-input"
            placeholder=" Name"
          />
          <input
            type="text"
            className="name-number-email-input"
            placeholder=" Contact Number"
          />
          <input
            type="text"
            className="name-number-email-input"
            placeholder=" Email"
          />
          <input type="text" className="message-input" placeholder=" Mesaage" />
          <button className="submit">SUBMIT</button>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/harsh-zota-0a639716a/"
              target="_blank"
              className="linkedIn"
            >
              <img src={image} alt="" />
            </a>
            <a
              href="https://github.com/Haaarrrssshhh/"
              target="_blank"
              className="github"
            >
              <img src={image2} alt="" />
            </a>
            <a
              href="https://www.instagram.com/harshzota99/"
              target="_blank"
              className="github"
            >
              <img src={image3} alt="" />
            </a>
            <a
              href="https://twitter.com/HarshZota"
              target="_blank"
              className="github"
            >
              <img src={image4} alt="" />
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/home";
import Experience from "./screens/experience";
import "./styles/main.scss";
import Cursor2 from "./components/cursor-2";
import { PageTransition } from "@steveeeie/react-page-transition";
import Projects from "./screens/projects";

function App() {
  return (
    <Router>
      {/* <PageTransition> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
      {/* </PageTransition> */}
    </Router>
  );
}

export default App;

import React, { useEffect } from "react";
import Header from "../components/header";
import image from "../assets/images/portfolio-website-image.png";
import image2 from "../assets/images/hp-admin.png";
import image3 from "../assets/images/try.jpg";
import ecommerce from "../assets/images/ecommerce.png";

import { resize } from "../components/cursor-2";

export default function Projects() {
  useEffect(() => {
    resize();
  });
  return (
    <div>
      <Header></Header>
      <div className="projects-container">
        <div className="projects-card">
          <div className="img-box">
            <img src={image} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>My Portfolio Website</h2>
              <p>
                This is an interactive simple project to present myself along
                with my web Development skills. I have also mentioned about
                myself and a small brief about the past industry experience I
                have.
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Havelli Patti Directory</h2>
              <p>
                In this particular project, I made the whole panel from scratch.
                This particular panel controls all different functional
                activities in the mobile Application along with it. This is a
                fully functional website, with multiple API integration,
                validations and authentications.
              </p>
              <button className="submit">
                <a href="https://admin.havelipatti.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={ecommerce} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Organic</h2>
              <p>
                This project is a basic demo of how your ecommerce website may
                look like. I have defined here a flow of pages, some basic
                functionality, payments pages and a cart page. Here I also have
                used nested routing to manage routes throughout my project.
              </p>
              <a href="https://ecommerce.harshzota.com" target="_blank">
                <button className="submit">View Project</button>
              </a>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image3} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Coming Soon....</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="projects-card">
          <div className="img-box">
            <img src={image2} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>Project Name</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </p>
              <button className="submit">
                <a href="https://www.harshzota.com/" target="_blank">
                  View Project
                </a>
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
